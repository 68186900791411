.member_card {
    background-color: transparent;
    border: 1px solid #545454;
    padding: 20px;
    text-align: center;
    max-width: 250px;
    margin: 10px;
    border-radius: 24px;
    height: 100%;
    min-height: 370px;
}

.image_container img {
    width: 90%;
    height: 90%;
    object-fit: cover;
}

.member_info{
    margin-top: 25px;
}

.member_role {
    font-size: 1.6rem;
    color: var(--sls-primary-color);
    margin-top: 15px;
    text-transform: uppercase;
    font-family: var(--sls-secondary-font-family);
}

.member_name {
    font-size: 1.2rem;
    color: #717171;
    margin-top: 5px;
    font-family: var(--sls-secondary-font-family);
}