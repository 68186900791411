.card {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: -0.82px -0.82px 15.08px 7.42px #00000021;
    padding: 22px;
    max-width: 360px;
    margin: 20px auto;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.5);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.name {
    font-family: var(--sls-secondary-font-family);
    font-size: 24px;
    font-weight: 700;
    color: #DC9F17;
    margin: 10px 0;
    position: relative;
    padding-bottom: 20px; 
}

.name::after {  
    content: "";
    position: absolute;
    bottom: 0;  
    left: 50%;
    width: 20%;
    height: 4.5px;
    background-color: #000000B2;
    transform: translateX(-50%);
}

.testimonial {
    margin-top: 30px;
    width: 90%;
    font-family: var(--sls-primary-font-family);
    font-size: 16px;
    font-weight: 400;
    text-align: left !important;
}

@media (min-height: 1600px) {
    .card {
        max-width: none;
    }
}
