.title {
    font-size: 25px;
    font-weight: 600;
    color: #272622;
    font-family: 'Poppins';
    width: 80%;
}

.container {
    width: 100%;
}

.title span {
    margin-left: 20px;
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
}

.active_details_btn {
    padding: 12px;
    border: none;
    background: var(--sls-primary-color);
    color: white;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 500;
    border-radius: 8px;
    width: 49%;
}

.details_btn {
    padding: 12px;
    border: none;
    background: white;
    color: #717171;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    border-radius: 8px;
    width: 49%;
    border: 1px solid #D2D2D2;
}

.main_image_container {
    width: 100%;
    aspect-ratio: 1 / 1;
    overflow: hidden;
    position: relative;
}

.main_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.images_container {
    width: 90%;
}

.card {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 90%;
    height: auto;
    border: 1px solid #D2D2D2;
    border-radius: 10px;
    margin: auto;
    padding: 20px;
}

.form_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.number {
    width: 100%;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #F5F5F5;
    border-radius: 5px;
    margin: 10px;
    background-color: #F5F5F5;
}

.number img {
    width: 35px;
}

.separator {
    color: var(--sls-primary-color);
    padding: 0 25px;
}

.number a {
    font-size: 20px;
    font-weight: 700;
    text-decoration: none;
    color: var(--sls-primary-color);
}

.form_input {
    width: 100%;
    height: 58px;
    margin: 10px;
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    padding-left: 30px;
    padding-right: 30px;
}

.textarea {
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    width: 100%;
    margin: 10px;
    padding: 24px 30px 0;
    height: 175px;
}

.send_btn {
    padding: 10px 30px;
    border: none;
    background: var(--sls-primary-color);
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 700;
    margin: 5px;
    border-radius: 8px;
    transition: transform 0.3s ease;
    width: 100%;
}

.buttons {
    display: flex;
    margin-bottom: 5px;
}


@media (max-width: 768px) {
    .container {
        width: 100%;
    }
}