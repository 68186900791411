.footer_container {
    position: relative;
}

.footer_bg_image {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: -1;
}

.overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 250%;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: -1;
}

.content {
    position: relative;
    z-index: 1;
    padding-bottom: 50px;
}

.contact_container {
    align-items: center;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    overflow: hidden;
}

.contact_title {
    font-family: Poppins;
    font-size: 26px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.contact_text {
    font-family: Poppins;
    font-size: 14px;
    margin-bottom: 50px;
}

.contact_info {
    font-family: var(--sls-secondary-font-family);
    font-size: 14px;
    font-weight: 400;
    width: 100%;
}

.contact_icon {
    margin-right: 10px;
}

.links {
    text-decoration: none;
    color: #fff;
}

.links:hover {
    color: var(--sls-primary-color);
}

.separator {
    height: 3px;
    background-color: var(--sls-primary-color);
}

.footer_text {
    color: var(--sls-primary-color);
    font-family: var(--sls-secondary-font-family);
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}

.send_btn {
    padding: 10px 30px;
    border: none;
    background: var(--sls-primary-color);
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    margin: 5px;
    border-radius: 4px;
    transition: transform 0.3s ease;
}

.send_btn:active {
    transform: scale(0.98);
}