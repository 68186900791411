.social_links {
  position: absolute;
  bottom: 40%;
  right: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
}

.icon {
  height: 1.5vw;
  width: 1.5vw;
  display: block;
  margin: 8px 0;
}


@media (min-width: 992px) and (max-width: 1199.98px) {
  .social_links {
    bottom: 20%;
    right: 3.5%;
  }
}

@media (min-width: 1199.99px) and (max-width: 1600px) {
  .social_links {
    bottom: 20%;
   
  }
}