.feature_card_wrapper {
  position: relative;
  width: 100%;
  max-width: 320px; 
  margin: 0 auto;

}

.feature_card_container {
  position: relative;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 8px;
}

.feature_card_container img {
  width: 100%;
  height: auto;
  display: block;
}

.feature_card_container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.feature_title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  z-index: 2;
  font-family: var(--sls-secondary-font-family);
  font-size: 28px;
  font-weight: 400;
  text-align: center;
}

.feature_title::after {
  content: "";
  display: block;
  width: 50px;
  height: 2px;
  background-color: var(--sls-primary-color);
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.red_border_card {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 100%;
  height: 100%;
  border: 2px solid var(--sls-primary-color);
  z-index: -1;
  border-radius: 8px;
  max-width: 300px;
  margin: 0 auto;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
