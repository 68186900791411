.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.label {
  font-size: 14px;
  margin-bottom: 2px;
  font-weight: bold;
  color: var(--sls-primary-color);
}

.input {
  font-size: 16px;
  padding: 10px 5px;
  border: none;
  border-bottom: 0.1px solid #000;
  transition: border-bottom-color 0.3s;
}

.input:focus {
  outline: none;
  border-bottom-color: var(--sls-primary-color);
}