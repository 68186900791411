.title_container {
    position: relative;
    display: inline-block;
    padding: 10px;
    margin-top: 50px;
}

.title_container::before {
    content: "";
    position: absolute;
    top: -25px;
    width: 100px;
    height: 25px;
    background-color: #FFFFFF;
    border: 3px solid #DB9E17;
    border-bottom: none;
    transform: translateX(-50%);
}

.title_container::after {
    content: "";
    position: absolute;
    bottom: -10px;
    width: 100px;
    height: 3px;
    background-color: #DB9E17;
    transform: translateX(-50%);
}


@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.title {
    background: linear-gradient(90deg, #E0A31A 0%, #F9CA37 100%);
    font-family: var(--sls-secondary-font-family);
    font-size: 50px;
    font-weight: 400;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: fadeIn 1s ease-in-out;
    -webkit-animation: fadeIn 1s ease-in-out;
}

.text {
    font-family: var(--sls-primary-font-family);
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    max-width: 70%;
    margin: 0 auto;
    color: #717171;
}


@media (max-width: 992px) {  
    .title {
        font-size: 40px;
    }
    
    .text {
        min-width: 100%;
    }
}