.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: var(--sls-white-color);
    padding: 20px;
    text-align: center;
}

.status {
    font-family: Axis, sans-serif;
    font-size: 100px;
    font-weight: 800;
    margin: 0;
    background: linear-gradient(180deg, #E0A31A 0%, #F9CA37 100%);
    -webkit-background-clip: text; 
    -webkit-text-fill-color: transparent; 
    background-clip: text; 
    text-fill-color: transpareny; 
}
