@font-face {
    font-family: 'Bageo';
    src: url('../fonts/bageo/Bageo.otf') format('opentype'),
        url('../fonts/bageo/Bageo.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/Open_Sans/OpenSans-VariableFont_wdth\,wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Axis';
    src: url('../fonts/axis/Axis\ Extrabold.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Quahon';
    src: url('../fonts/quahon/Quahon.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@import 'bootstrap/dist/css/bootstrap.min.css';

:root {
    --sls-primary-color: #D19B16;
    --sls-black-color: #000000;
    --sls-white-color: #ffffff;
    --sls-primary-font-family: 'Open Sans', sans-serif;
    --sls-secondary-font-family: 'Bageo', sans-serif;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    overflow-x: hidden;
}

.slick-prev::after {
    color: #000 !important;
    content: '‹' !important;
    font-size: 30px;
}
.slick-next::after {
    color: #000 !important;
    content: '›' !important;
    font-size: 30px;
}
.slick-next, .slick-prev{
    top:35%;
}
