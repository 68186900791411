.partners_banner {
    background-color: #000;
    color: #fff;
    padding: 50px 0;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.partners_title {
    font-size: 42px;
    font-weight: 400;
    font-family: var(--sls-secondary-font-family);
    margin-bottom: 20px;
}

.partners_text {
    font-size: 16px;
    font-weight: 400;
    font-family: var(--sls-secondary-font-family);
    margin-top: 10px;
    margin-right: 40%;
}

.partner_logo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.partner_logo img {
    /* max-width: 100px; */
    margin-bottom: 10px;
}

.partner_name {
    font-size: 14px;
    font-weight: 400;
    font-family: var(--sls-secondary-font-family);
}

@media (max-width: 992px) {
    .box_container {
        display: none;
    }
}

.box_container {
    position: absolute;
    bottom: 80px;
    left: -35px;

}

.box {
    position: absolute;
    border-right: 4px solid var(--sls-primary-color);
    border-bottom: 4px solid var(--sls-primary-color);
    background: transparent;
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    -o-transform: scaleX(-1);
}

.b1 {
    width: 100px;
    height: 100px;
    margin-left: 10px;
    margin-bottom: 10px;
}

.b2 {
    width: 110px;
    height: 110px;
    margin-left: 0px;
    margin-bottom: 0px;
}

.b3 {
    width: 120px;
    height: 120px;
    margin-left: -10px;
    margin-bottom: -10px;
}

.b4 {
    width: 130px;
    height: 130px;
    margin-left: -20px;
    margin-bottom: -20px;
}

.b5 {
    width: 140px;
    height: 140px;
    margin-left: -30px;
    margin-bottom: -30px;
}

@media (max-width: 768px) {
    .partners_title {
        font-size: 38px;
    }

    .partners_text {
        text-align: justify;
        margin-right: 1%;
    }
}