.filter_section {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 40px;
  text-align: center;
}

.filter_by {
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--sls-primary-color);
  font-family:var(--sls-secondary-font-family);
  height: 100%;
  
}

.filters_container {
  display: flex;
  gap: 20px;
  text-align: center;
  justify-content: center;
  height: 40px;

}

.select {
  font-family: var(--sls-secondary-font-family);
  font-weight: 400;
  border: none;
  background: transparent;
  text-align: center;
  padding: 5px;
}

@media (max-width: 768px) {
  .filter_section {
    height: auto;
    width: 100%;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .filter_by {
    font-weight: 400;
    color: var(--sls-primary-color);
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 10px;
   
  }

  .filters_container {
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-width: 300px;
    text-align: center;
    height: auto;
  }

  .select {
    width: 100%;
    padding: 10px;
  }
}