.up_arrow {
    text-align: center;
    margin-top: 20px;
}

.up_arrow_btn {
    width: 40px;
    height: 40px;
    border: none;
    background: #D19B16;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.up_arrow_icon {
    width: 20px;
    height: auto;
}