.team_container {
    position: relative;
    overflow: hidden;
    text-align: center;
}

.global_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

.circles {
    position: absolute;
    top: 42%;
    right: 0;
    transform: translateY(-50%);
    pointer-events: none;
    opacity: 30%;
    filter: blur(3px);
    -webkit-filter: blur(3px);
}

.circle_1,
.circle_2 {
    position: absolute;
    border: 4px solid #D19B1699;
    border-radius: 50%;
    background: transparent;
}

.circle_1 {
    width: 100px;
    height: 100px;
    top: -320px;
    right: -50px;
}

.circle_2 {
    width: 200px;
    height: 200px;
    top: -370px;
    right: -100px;
}

.left_square {
    filter: blur(5px);
    -webkit-filter: blur(5px);
}

.square_1 {
    position: absolute;
    bottom: 200px;
    left: 100px;
    width: 40px;
    height: 40px;
    border: 2px solid #5C5C5C;
    z-index: -2;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    background: transparent;
}

.square_2 {
    position: absolute;
    bottom: 250px;
    left: 150px;
    width: 30px;
    height: 30px;
    border: 2px solid var(--sls-primary-color);
    z-index: -2;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    background: transparent;
}

.square_3 {
    position: absolute;
    bottom: 180px;
    left: 160px;
    width: 20px;
    height: 20px;
    border: 2px solid #5C5C5C;
    z-index: -2;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    background: transparent;
}

.right_square {
    filter: blur(3px);
    -webkit-filter: blur(3px);
}

.bordered_square {
    position: absolute;
    bottom: 100px;
    right: 150px;
    width: 50px;
    height: 50px;
    background: transparent;
    border: 2px solid var(--sls-primary-color);
    z-index: -3;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
}

.black_square {
    position: absolute;
    bottom: 125px;
    right: 150px;
    width: 50px;
    height: 50px;
    background-color: #5C5C5C;
    z-index: -2;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
}

@media (max-width: 992px) {
    .right_square {
        display: none;
    }

    .circle_1,
    .circle_2,
    .left_square,
    .right_square {
        display: none;
    }
}