.img_container:focus {
    outline: none; 
}

.img_container img {
    width: 90% !important;
    aspect-ratio: 1/1;
    margin: 10px;
    cursor: pointer;
    border-radius: 8px;
}

.bordered{
    border: solid 2px #FFC700;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}