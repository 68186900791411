.feature_cards_container {
    width: 80%;
    margin-left: auto;
}

.company_title {
    font-family: var(--sls-secondary-font-family);
    font-size: 32px;
    font-weight: 400;
    color: var(--sls-primary-color);
    position: relative;
    margin-bottom: 20px;
}

.company_title::after {
    content: "";
    display: block;
    width: 60%;
    height: 2px;
    background-color: var(--sls-primary-color);
    position: absolute;
    left: 0;
    bottom: -5px;
}

@media (max-width: 992px) {
    .feature_cards_container {
        width: 100%;
        margin-left: 0;
    }

    .box_container {
        display: none;
    }
}

.box_container {
    position: absolute;
    bottom: 100px;
    left: 100px;

}

.box {
    position: absolute;
    border-right: 4px solid var(--sls-primary-color);
    border-bottom: 4px solid var(--sls-primary-color);
    opacity: 40%;
    background: transparent;
    transform: scaleX(-1);
}

.b1 {
    width: 100px;
    height: 100px;
    margin-left: 10px;
    margin-bottom: 10px;
}

.b2 {
    width: 110px;
    height: 110px;
    margin-left: 0px;
    margin-bottom: 0px;
}

.b3 {
    width: 120px;
    height: 120px;
    margin-left: -10px;
    margin-bottom: -10px;
}

.b4 {
    width: 130px;
    height: 130px;
    margin-left: -20px;
    margin-bottom: -20px;
}

.b5 {
    width: 140px;
    height: 140px;
    margin-left: -30px;
    margin-bottom: -30px;
}



@media (min-width: 992px) and (max-width: 1199.98px) {
    .box {
        position: absolute;
        left: 0;
        bottom: -40px;
    }

    .b1 {
        margin-left: 0px;
        margin-bottom: 0px;
    }

    .b2 {
        margin-left: -10px;
        margin-bottom: -10px;
    }

    .b3 {
        margin-left: -20px;
        margin-bottom: -20px;
    }

    .b4 {
        margin-left: -30px;
        margin-bottom: -30px;
    }

    .b5 {
        margin-left: -40px;
        margin-bottom: -40px;
    }
}