.feature_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.feature_square {
    border: 2px solid var(--sls-primary-color);
    width: 60px;
    height: 60px;
    border-radius: 8px;
    transform: rotate(45deg);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    margin: 20px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
}

.feature_square img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
}

.feature_title {
    font-family: var(--sls-secondary-font-family);
    font-size: 20px;
    font-weight: 400;
    color: var(--sls-primary-color);
    text-transform: uppercase;
    margin: 0;
    position: relative;
    padding-bottom: 10px;
}

.feature_title::after {
    content: "";
    display: block;
    width: 50px;
    height: 2px;
    background-color: var(--sls-primary-color);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
}

.feature_text {
    width: 90%;
    font-family: var(--sls-primary-font-family);
    font-size: 18px;
    font-weight: 400;
    color: #5C5C5C;
}

@media (max-width: 992px) {
    .feature_text {
        font-size: 14px;
    }

    .feature_title {
        font-size: 18px;
    }
}