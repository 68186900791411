.stats_container {
    background-color: #1D1D1D;
}

.stats_numbers {
    font-family: Axis;
    font-size: 50px;
    font-weight: 800;
}

.stats_texts {
    font-family: var(--sls-secondary-font-family);
    font-size: 24px;
    font-weight: 400;
}

.separator {
    display: none;
}

@media (max-width: 992px) {
    .separator {
        display: block;
        width: 80%;
        height: 3px;
        background-color: #fff;
        margin: 15px 0px;
    }
}