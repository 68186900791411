.title_container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 20px;
}

.title {
    background: linear-gradient(180deg, #E0A31A 0%, #F9CA37 100%);
    font-family: var(--sls-secondary-font-family);
    font-size: 4.2rem;
    font-weight: 400;
    line-height: 75.86px;
    letter-spacing: 0.05em;
    text-align: left;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    margin-bottom: 5px;
    margin-left: 5px;
}


.title_container::before {
    content: '';
    position: absolute;
    left: -1rem;
    top: 0.4rem;
    height: calc(90% - 20px);
    width: 0.375rem;
    background-color: var(--sls-primary-color);
}

.title_container::after {
    content: '';
    position: absolute;
    bottom: -0.625rem;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 1px;
    background-color: var(--sls-primary-color);
}

.subtitle_container {
    margin-top: 2.5rem;
    margin-bottom: 1.25rem;
    text-align: left;
}

.subtitle {
    font-family: var(--sls-secondary-font-family);
    font-size: 1.75rem;
    font-weight: 400;
    margin-bottom: 0.625rem;
}

.description {
    font-family: var(--sls-primary-font-family);
    font-size: 1.125rem;
    font-weight: 400;
    color: #626262;
    line-height: 1.5;
    margin-bottom: 1.5rem;
}

.hours {
    font-family: var(--sls-primary-font-family);
    font-size: 1.25rem;
    font-weight: 400;
    color: var(--sls-black-color);
    padding: 0.625rem;
    position: relative;
    border: 2px solid transparent;
    border-image-source: linear-gradient(180deg, #DE9F17 0%, #DAAE2E 45.09%, #FFD148 100%);
    border-image-slice: 1;
}

@media (min-width: 1090px) and (max-width: 1180px) {
    .description{
        width: 90%;
    }
  }
@media (min-width: 992px) and (max-width: 1089px) {
    .description{
        width: 80%;
    }
  }
  

@media (max-width: 1024px) {
    .title {
        font-size: 3.5rem;
        line-height: 4rem;
        margin-bottom: 1rem;
    }

    .subtitle {
        font-size: 2rem;
    }

    .description {
        font-size: 1.375rem;
        margin-bottom: 1rem;
    }

    .hours {
        font-size: 1.5rem;
    }
}

@media (max-width: 768px) {
    .title {
        font-size: 3.6rem;
        line-height: 4.5rem;
       
    }

    .subtitle {
        font-size: 2rem;
    }

    .description {
        font-size: 1.2rem;
        margin-bottom: 2rem;
        text-align: justify;
    }

    .hours {
        font-size: 1rem;
        margin-bottom: 2.5rem;
    }
}


@media (min-width: 2000px) {
    .title {
        font-size: 8rem;
        line-height: 9rem;
       
    }

    .subtitle {
        font-size: 4.5rem;
    }

    .description {
        font-size: 2.2rem;
        margin-bottom: 2rem;
        text-align: justify;
    }

    .hours {
        font-size: 2.2rem;
        margin-bottom: 0rem;
    }
}