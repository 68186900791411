.Choose_us{
    margin-top: 30vh !important;
}

.stats_container {
    position: relative;
    background-image: url('./../../../assets/images/footer-img.jpeg');
    background-size: cover;
    background-position: center 60%;
    background-repeat: no-repeat;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stats_container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1;
}

.stat_container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 120px;
    border: 2px solid white;
    padding: 10px;
    box-sizing: border-box;
    color: white;
    z-index: 2;
    margin: 20px;

}

.stats_numbers {
    font-family: Axis, sans-serif;
    font-size: 28px;
    font-weight: 800;
}

.stats_texts {
    font-family: var(--sls-secondary-font-family);
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
}

.left_square {
    filter: blur(5px);
    -webkit-filter: blur(5px);
}

.bordered_square {
    position: absolute;
    bottom: -4vw;
    right: 65vw;
    width: 50px;
    height: 50px;
    background: transparent;
    border: 2px solid #D19B16;
    z-index: -2;
}

.black_square {
    position: absolute;
    bottom: -5vw;
    right: 64vw;
    width: 50px;
    height: 50px;
    background-color: #5C5C5C;
    z-index: -3;

}

.right_square {
    filter: blur(5px);
    -webkit-filter: blur(5px);
}

.square_1 {
    position: absolute;
    /* bottom: 80px; */
    right: 100px;
    width: 50px;
    height: 50px;
    border: 2px solid #5C5C5C;
    z-index: -2;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    background: transparent;
}

.square_2 {
    position: absolute;
    bottom: 10px;
    right: 50px;
    width: 40px;
    height: 40px;
    border: 2px solid #D19B16;
    z-index: -2;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    background: transparent;
}

.square_3 {
    position: absolute;
    bottom: -45px;
    right: 25px;
    width: 30px;
    height: 30px;
    border: 2px solid #5C5C5C;
    z-index: -2;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    background: transparent;
}

.square_4 {
    position: absolute;
    bottom: -80px;
    right: 75px;
    width: 20px;
    height: 20px;
    border: 2px solid #D19B16;
    z-index: -2;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    background: transparent;
}

@media (max-width: 768px) {
    .Choose_us{
        margin-top: 3vh !important;
    }
    .bordered_square,
    .black_square {
        display: none;
    }

}

@media(max-width:2100px) {
    .black_square {
        bottom: -8vw;
        right: 75vw;
    }

    .bordered_square {
        bottom: -9vw;
        right: 76vw;
    }
}

@media(min-width:2000px) {
    .square_1 {
        right: -50px;

    }

    .square_2 {
        right: -100px;

    }

    .square_3 {
        right: -125px;

    }

    .square_4 {
        right: -75px;
    }
}