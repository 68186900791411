.similar_title {
    font-family: var(--sls-primary-font-family);
    text-align: left;
    position: relative;
    margin-bottom: 0;
    width: 98.5%;
}

.similar_title h1 {
    font-weight: 600;
    margin: 0;
}

.gradient_line {
    display: inline-block;
    height: 4px;
    background: linear-gradient(90deg, #FFAC12 0%, #000000 94.96%);
    width: 20%;
}