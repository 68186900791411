.about_us_container {
    position: relative;
    padding: 20px;
    padding-top: 0;
    margin-bottom: 100px;
}

.image_container {
    width: 100%;
    text-align: right !important;
    position: relative;
}

.image_container img {
    border-radius: 8px;
}

.white_rectangle {
    position: absolute;
    top: 20px;
    right: 40px;
    width: 90%;
    max-width: 350px;
    min-height: 450px;
    background: #fff;
    border-radius: 8px;
    z-index: 2;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.color_rectangle {
    position: absolute;
    top: 0;
    right: 80px;
    width: 90%;
    max-width: 350px;
    min-height: 450px;
    background: #E2A61C;
    border-radius: 8px;
    z-index: 1;
}

.white_rectangle img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.about_title {
    font-family: Bageo;
    font-size: 2.5rem;
    font-weight: 400;
    color: var(--sls-primary-color);
    margin-top: 0;
}

.about_text {
    font-family: var(--sls-primary-font-family);
    font-size: 16px;
    font-weight: 400;
    text-align: justify;
    width: 90%;
    color: #717171;
}

.border-box {
    border-right: 4px solid var(--sls-primary-color);
    border-bottom: 4px solid var(--sls-primary-color);
    padding: 10px;
    margin: 10px;
    position: relative;
}


@media (max-width: 992px) {

    .about_us_container {
        margin-bottom: 0px;
    }

    .white_rectangle {
        position: relative;
    }

    .white_rectangle,
    .color_rectangle {
        right: 0;
        left: 0;
        margin: 0 auto;
        margin-bottom: 50px;
    }

    .box_container {
        display: none;
    }

    .white_rectangle {
        overflow: hidden;
    }

    .bordered_square,
    .black_square {
        display: none;
    }

    .about_text {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .white_rectangle {
        position: relative;
    }

    .white_rectangle,
    .color_rectangle {
        width: 100%;
        margin-bottom: 50px;
    }

    .about_text {
        margin-top: 20px;
    }

    .box_container {
        display: none;
    }

    .white_rectangle {
        overflow: hidden;
    }

    .bordered_square,
    .black_square {
        display: none;
    }

    .about_text {
        width: 100%;
    }
}

.box {
    position: absolute;
    border-right: 4px solid var(--sls-primary-color);
    border-bottom: 4px solid var(--sls-primary-color);
    background: transparent;
    opacity: 40%;
}


.b1 {
    width: 100px;
    height: 100px;

}

.b2 {
    width: 110px;
    height: 110px;

}

.b3 {
    width: 120px;
    height: 120px;

}

.b4 {
    width: 130px;
    height: 130px;

}

.b5 {
    width: 140px;
    height: 140px;

}

.box_container {
    position: absolute;
    bottom: 80px;
    right: 60px;
}

.bordered_square {
    position: absolute;
    bottom: -15px;
    right: -10px;
    width: 50px;
    height: 50px;
    background: transparent;
    border: 2px solid var(--sls-primary-color);
    z-index: -2;
}

.black_square {
    position: absolute;
    bottom: -40px;
    right: -40px;
    width: 50px;
    height: 50px;
    background-color: #000;
    z-index: -3;
}