.title {
    font-family: var(--sls-secondary-font-family);
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 10px;
}

.title_gold {
    color: var(--sls-primary-color);
}
.title_blue {
    color: #2A4B9A
}

.text_msg {
    font-weight: 600;
}

.text_msg.success {
    color: green;
}

.text_msg.error {
    color: red;
}

.input_container {
    border: 1.5px solid var(--sls-black-color);
    display: flex;
    align-items: center;
    width: 100%;
    transition: border-color 0.3s ease;
    -webkit-transition: border-color 0.3s ease;
    -moz-transition: border-color 0.3s ease;
    -ms-transition: border-color 0.3s ease;
    -o-transition: border-color 0.3s ease;
}

.input_container:focus-within {
    border-color: #D19B16;
}

.input {
    border: none;
    flex: 1;
    padding-left: 30px;
    padding-right: 30px;
    background: transparent;
    font-size: 16px;
    transition: background-color 0.3s ease;
    -webkit-transition: background-color 0.3s ease;
    -moz-transition: background-color 0.3s ease;
    -ms-transition: background-color 0.3s ease;
    -o-transition: background-color 0.3s ease;
}

.input:focus {
    outline: none;
    background: transparent;
}

.input_icon {
    width: auto;
    text-align: center;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    transition: transform 0.3s ease;
    -webkit-transition: transform 0.3s ease;
    -moz-transition: transform 0.3s ease;
    -ms-transition: transform 0.3s ease;
    -o-transition: transform 0.3s ease;
}

.input_icon:active {
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
}

.input_icon img {
    display: block;
    width: 100%;
    transition: opacity 0.3s ease;
    -webkit-transition: opacity 0.3s ease;
    -moz-transition: opacity 0.3s ease;
    -ms-transition: opacity 0.3s ease;
    -o-transition: opacity 0.3s ease;
}

@media (max-width: 576px) {
    .input_container {
        display: flex;
    }

    .input {
        width: 85%;
    }

    .input_icon {
        width: 15%;
    }

    .input_icon img {
        width: auto;
    }
}