.project_card {
    border: 1px solid var(--sls-primary-color);
    background-color: #fff;
    margin: 10px;
    margin-bottom: 30px;
    min-height: 520px;
    overflow: hidden;
}

.image_container {
    margin-top: 0.6vw;
    margin-bottom: 0.6px;
    width: 100%;
    aspect-ratio: 3/2;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
}

.image {
    width: 95%;
    height: 100%;
    object-fit: cover;
    margin: auto;
    box-shadow: 3px 3px 6px 0px #00000063;
}

.content {
    padding: 1vw;
}

.title {
    font-family: var(--sls-secondary-font-family), sans-serif;
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 8px;
    color: var(--sls-primary-color);
}

.text {
    font-family: var(--sls-secondary-font-family), sans-serif;
    opacity: 60%;
    color: var(--sls-black-color);
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 2px;
}

.separator {
    color: #FFDA00;
}

.location_icon {
    height: 1.1rem;
    vertical-align: middle;
    margin-right: 4px;
}

@media (max-width: 768px) {
    .project_card {
        min-height: 400px;
    }

    .title {
        
        font-size: 1.8rem;
      
    }

    .content {
        padding-left: 2.5vw;
    }
}