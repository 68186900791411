.contact_container {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: 100%;
  overflow: hidden;
}

.contact_row {
  width: 100%;

}

.contact_title {
  font-family: Poppins;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.contact_text {
  font-family: Poppins;
  font-size: 14px;
  margin-bottom: 20px;
  margin-bottom: 50px;
}

.contact_infos {
  background-color: black;
  color: #fff;
  border-radius: 8px;
  height: 100%;
  width: 100%;
  padding: 30px;
  margin: 0;
  width: 100%;
}

.contact_info {
  font-family: var(--sls-secondary-font-family);
  font-size: 14px;
  font-weight: 400;
  width: 100%;
}

.contact_icon {
  margin-right: 10px;
}

.icon {
  width: 30px;
  height: 30px;
  margin: 6px;
}


.send_btn {
  padding: 10px 30px;
  border: none;
  background: var(--sls-primary-color);
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  margin: 5px;
  border-radius: 4px;
  transition: transform 0.3s ease;
  -webkit-transition: transform 0.3s ease;
  -moz-transition: transform 0.3s ease;
  -ms-transition: transform 0.3s ease;
  -o-transition: transform 0.3s ease;
}

.send_btn:active {
  transform: scale(0.98);
  -webkit-transform: scale(0.98);
  -moz-transform: scale(0.98);
  -ms-transform: scale(0.98);
  -o-transform: scale(0.98);
}

.circles {
  position: relative;

}

.circle_1,
.circle_2 {
  position: absolute;
  background-color: #000;
  border-radius: 50%;

}

.circle_1 {
  width: 100px;
  height: 100px;
  bottom: -10px;
  right: 10px;
  background-color: rgba(255, 249, 249, 0.13);
}

.circle_2 {
  width: 200px;
  height: 200px;
  bottom: -100px;
  right: -120px;
  background-color: rgba(255, 255, 255, 0.12);

}

.links {
  text-decoration: none;
  color: #fff;
}

.links:hover {
  color: var(--sls-primary-color);
}

@media (max-width: 768px) {
  .contact_row {
    width: auto;
  }
}