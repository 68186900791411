.hero {
    width: 100vw;
    text-align: center;
}

.hero_container {
    width: 90vw;
    margin: auto;
}

.content_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    z-index: 1;
}

.image_container {
    position: absolute;
    top: 0;
    right: 0;
    height: 90vh;
    z-index: -2;
}

.hero_img {
    width: 100%;
}

.hero_heading {
    font-family: var(--sls-secondary-font-family);
    font-size: 33px;
    font-weight: 400;
    position: relative;
    margin-top: 13vh;
    padding: 20px;
}

.hero_heading::after {
    content: "";
    display: block;
    width: 120px;
    height: 3.5px;
    background-color: #D69D16;
    margin: 10px auto 0;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
}

@media (max-width: 576px) {
    .hero_container {
        width: 100vw;
        margin: 0;
        padding: 50px;
    }

    .content_container {
        height: auto;
        margin-top: 10px;
        margin-bottom: 0;
    }

    .hero_heading {
        margin-top: 10%;
    }
}

@media (max-width: 768px) {
    .hero_container {
        width: 100vw;
        margin: 0;
        padding: 20px;
    }

    .content_container {
        height: auto;
        margin-top: 10px;
        margin-bottom: 0;
    }

    .image_container {
        height: 80vh;
    }

    .hero_heading {
        margin-top: 10px;
    }
}


@media (min-width: 769px) and (max-width: 992px) {
    .hero_heading {
        margin-top: 5%;
    }
}

@media (min-width: 1540px) {
    .content_container {
        padding-right: 10%;
    }
}

@media (max-height: 599px) {
    .image_container {
        height: 95vh;
    }

    .content_container {
        height: 70vh;
        margin-top: 10px;
    }
}

@media (min-height: 600px) {
    .image_container {
        height: 90vh;
    }

    .content_container {
        height: auto;
        margin-top: 10px;
    }
}

@media (min-height: 800px) {
    .image_container {
        height: 80vh;
    }

    .content_container {
        margin-top: 15px;
    }
}

@media (min-height: 1400px) {
    .image_container {
        height: 67vh;
    }

    .content_container {
        height: 60vh;
        margin-top: 15px;
    }
}