.description {
    padding-right: 20px;
}

.description_title {
    font-size: 24px;
    color: #6B6B6B;
    padding-bottom: 16px;
    border-bottom: 2px solid #E4E4E4;
}

.arrow_img {
    height: 20px;
}

.description_text {
    color: #181818;
    font-size: 19px;
    font-weight: 400;
}

.location {
    display: flex;
    gap: 1.2vw;
    text-align: center;
    align-content: center;
    margin: 15px;
}

.location p {
    margin: auto 0;
    color: #6B6B6B;
}

.location img {
    width: 20px;
}

.value {
    color: #DE9F17
}

.infos1 {
    display: flex;
    flex-direction: row;
    border: 0.1px solid #E4E4E4;
    border-left: 0px;
    width: fit-content;
    margin-bottom: 4vh;
}

.info1 {
    font-weight: 600;
    display: flex;
    text-align: center;
    align-content: center;
    gap: 1vw;
    border-left: 0.1px solid #E4E4E4;
    height: 48;
    padding: 10px 20px;
}

.info2 {
    font-weight: 400;
    display: flex;
    gap: 1.2vw;
    font-size: 20px;
}

.container1,
.container2 {
    width: 45%;
}

.details_content {
    width: 80%;
}

.description {
    width: 85%;
}

.gradient_line {
    display: inline-block;
    height: 4px;
    background: linear-gradient(90deg, #FFAC12 0%, #000000 94.96%);
    width: 30%;
}

.right_border::after {

    content: "";
    position: absolute;
    right: 32px;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #D2D2D2;
}

.greyline {
    display: inline-block;
    height: 2px;
    background: #E4E4E4;
    width: 75%;
}


@media (max-width: 768px) {
    .details_content {
        width: 100%;
    }

    .right_border::after {
        display: none;
    }
}