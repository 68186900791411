.navbar_container {
    padding: 15px 15px 10px;
}

.navbar_container_border {
    padding: 15px 15px 10px;
    border-bottom: 1px solid #979797;
}

.navbar_toggle {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 25px;
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 0;
    z-index: 1101;
    position: relative;
}

.bar1,
.bar2,
.bar3 {
    width: 30px;
    height: 3px;
    background-color: var(--sls-primary-color);
    transition: transform 0.4s ease, opacity 0.4s ease, background-color 0.4s ease;
    border-radius: 2px;
}

.bar1 {
    transform-origin: top right;
}

.bar2 {
    width: 20px;
    align-self: flex-end;
    transition: opacity 0.4s ease, width 0.4s ease;
}

.bar3 {
    transform-origin: bottom right;
}

.open .bar1 {
    transform: rotate(-45deg);
    background-color: var(--sls-primary-color);
}

.open .bar2 {
    opacity: 0;
    width: 30px;
}

.open .bar3 {
    transform: rotate(45deg);
    background-color: var(--sls-primary-color);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
}

.nav_link {
    display: block;
    text-decoration: none;
    background: none;
    font-family: var(--sls-secondary-font-family);
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--sls-black-color);
    margin: 0 8px;
}

.nav_link_active {
    display: block;
    text-decoration: none;
    background: none;
    font-family: var(--sls-secondary-font-family);
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--sls-primary-color) !important;
    position: relative;
    margin: 0 8px;
}

.separator {
    color: #FFDA00;
}

.white_separator {
    color: #fff;
}

.a {
    text-decoration: none;
    color: #000;
}

.a:hover {
    color: var(--sls-primary-color);
}

.navbar_brand {
    /* width: 95%; */
    margin: 0;
}

@media (max-width: 991px) {

    .navbar_toggle {
        display: flex;
    }

    .navbar_menu {
        display: none;
    }

    .navbar_menu_expanded {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        background: rgba(151, 170, 106, 0.3);
        backdrop-filter: blur(8px);
        z-index: 1050;
        padding: 20px;
        justify-content: center;
        align-items: center;
    }

    .nav_link_active {
        font-size: 24px;
    }

    .navbar_contact {
        display: none;
    }

    .nav_link {
        font-size: 32px;
        padding: 15px;
        text-align: center;
    }

    .toggle_icon {
        font-size: 24px;
    }

    .navbar_brand {
        /* width: 10rem; */
        margin: 0;
    }
}

@media (min-width: 992px) {

    .nav_link_active::after {
        content: '';
        position: absolute;
        left: 10%;
        bottom: 8px;
        width: 80%;
        height: 2px;
        background-color: var(--sls-primary-color);
    }

    .navbar_menu {
        width: 100vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .navbar_contact {
        display: flex;
        align-items: center;
        font-family: var(--sls-secondary-font-family);
        font-size: 0.8rem;
        color: var(--sls-black-color);
    }

    .navbar_contact_border {
        border-bottom: 1px solid var(--sls-primary-color);
    }

    .nav_link,
    .nav_link_active {
        font-size: 1rem;
    }
}