.container {
    display: block;
}

.social_links {
    display: inline-block;
    text-align: end;
}

.social_link {
    display: inline-block;
    margin: 0 5px;
}

.icon {
    width: 30px;
    height: 30px;
}